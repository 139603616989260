export const categories = [
  {
    id: 1,
    img: require("./assets/images/category1.webp"),
    title: "VELO", //feders zelena - crvena,  3-crvena(svetlo plava/crna), crvena, siberia - svetlo plava, iceberg - narandzasta, grant-zlatna
    color: "#f42d3d",
    desc: "Pružaju jedinstveno iskustvo uživanja u snusu.",
    descEN: "Provides a unique snus enjoyment experience.",
    meta_description:
      "Odaberite svoj omiljeni ukus i uživajte u jedinstvenom iskustvu bez štetnih uticaja na okolinu. Isprobajte Velo nikotinske proizvode danas i uverite se zašto su postali popularni među ljubiteljima nikotina širom sveta.",
    keywords: "velo snus, velo snus cena",
  },
  {
    id: 2,
    img: require("./assets/images/category3.webp"),
    title: "PABLO",
    color: "#f91f2b",
    desc: "brend koji se fokusira na visokokvalitetni i luksuzni snus, sa dodatkom prirodnih aroma i ekstrakata.  ",
    descEN:
      "A brand that focuses on high-quality and luxurious snus with added natural flavors and extracts.",
    meta_description:
      "Otkrijte jedinstvenu kategoriju Pablo nikotinskih proizvoda, koja nudi visoko kvalitetne proizvode sa prirodno uzgojenim duvanom i različitim aromama. Otkrijte pablo snus razlicitih mg jacina ",
    keywords: "pablo snus, pablo snus mg",
  },
  {
    id: 3,
    img: require("./assets/images/category2.webp"),
    title: "GARANT",
    color: "#f4d35e",
    desc: "brend koji nudi tradicionalni snus u različitim ukusima",
    descEN: "A brand that offers traditional snus in various flavors.",
    meta_description:
      " Garant snus nikotinski proizvodi proizvodi pružaju diskretan i jednostavan način za uživanje u nikotinu bez dima i neprijatnih mirisa. Najbolja garant cena proizvoda u regionu.",
    keywords: "garant snus, garant cena",
  },
  {
    id: 4,
    img: require("./assets/images/lyftCategory.webp"),
    title: "LYFT",
    color: "#f91f2b",
    desc: "brend koji nudi nikotinske proizvode bez duvana, sa ukusom i bez mirisa",
    descEN:
      "A brand that offers tobacco-free nicotine products with taste and no scent.",
    meta_description:
      "LYFT snus nikotinski proizvodi pružaju diskretan i jednostavan način za uživanje u nikotinu bez dima i neprijatnih mirisa. Najbolja cena LYFT proizvoda u regionu.",
    keywords: "LYFT snus, cena LYFT, nikotinski proizvodi bez duvana",
  },
  {
    id: 5,
    img: require("./assets/images/fedrsCategory.webp"),
    title: "FEDRS",
    color: "#0d6129",
    desc: "za diskretne korisnike",
    descEN: "For discreet users.",
    meta_description:
      "FEDRS snus nikotinski proizvodi su idealan izbor za sve ljubitelje čistog i snažnog osećaja nikotina. Svojom raznovrsnom ponudom proizvoda FEDRS odgovara na potrebe svih korisnika. Najbolje cene FEDRS proizvoda u regionu.",
    keywords: "FEDRS snus, FEDRS cena",
  },
  {
    id: 6,
    img: require("./assets/images/icebergCategory.webp"),
    title: "ICEBERG",
    color: "#f5841f",
    desc: "brend koji nudi raznolikost ukusa i jaku snagu nikotina",
    descEN:
      "A brand that offers a variety of flavors and strong nicotine power.",
    meta_description:
      "ICEBERG snus nikotinski proizvodi pružaju jedinstvene ukuse i snažan doživljaj nikotina. Odaberite svoj omiljeni ukus i uživajte u trenutku svežine i podsticaja. Najbolja cena za ICEBERG proizvode u regionu.",
    keywords: "iceberg snus, iceberg cena, snus srbija",
  },
  {
    id: 7,
    img: require("./assets/images/siberiaCategory.webp"),
    title: "SIBERIA",
    color: "#26cad3",
    desc: "savršeni za iskusne korisnike",
    descEN: "Perfect for experienced users.",
    meta_description:
      "Isprobajte intenzivan i dugotrajni efekat hladnoće uz SIBERIA nikotinske snusove. Otkrijte širok izbor različitih aroma i jačina u našoj ponudi. Bez dima, bez pepela - samo čisti užitak uz SIBERIA",
    keywords: "siberia snus, siberia cena, snus srbija",
  },
  {
    id: 8,
    img: require("./assets/images/kurwaCategory.webp"),
    title: "KURWA",
    color: "#ffd100",
    desc: "za avanturiste i istraživače",
    descEN: "For adventurers and explorers.",
    meta_description:
      "Otkrijte intenzivan i dugotrajni efekat hladnoće sa KURWA nikotinskim snusovima. Ponosno predstavljamo raznovrsne arome i jačine koje će zadovoljiti i najiskusnije korisnike. Uživajte u čistom zadovoljstvu uz KURWA, bez dima i pepela.",
    keywords: "kurwa snus, kurwa cena, snus Srbija",
  },
  {
    id: 9,
    img: require("./assets/images/killaCategory.webp"),
    title: "KILLA",
    color: "#ff3838",
    desc: "za ljubitelje mentol arome",
    descEN: "For menthol flavor enthusiasts.",
    meta_description:
      "Iskusite intenzivnu mentol eksploziju sa Killa nikotinskim snusovima. Snažan i dugotrajan efekat hladnoće pruža poseban doživljaj. Killa snusovi nude različite jačine i arome, prilagođene vašem ukusu.",
    keywords: "killa snus, mentol",
  },
  {
    id: 10,
    img: require("./assets/images/rabbitCategory.webp"),
    title: "RABBIT",
    color: "#45aaf2",
    desc: "za ljubitelje voćnih aroma",
    descEN: "For fruit flavor enthusiasts.",
    meta_description:
      "Istražite širok spektar voćnih aroma sa Rabbit nikotinskim snusovima. Uživajte u jedinstvenim i osvežavajućim ukusima koji će probuditi vaša čula. Dostupni u različitim jačinama, Rabbit pruža uživanje bez dima i pepela.",
    keywords: "rabbit snus, voćne arome, Rabbit Srbija",
  },
  {
    id: 11,
    img: require("./assets/images/thunderCategory.webp"),
    title: "THUNDER",
    color: "#E51D3B",
    desc: "za ljubitelje jakih aroma",
    descEN: "For strong flavor enthusiasts.",
    meta_description:
      "Iskusite snagu jakih aroma sa Thunder nikotinskim snusovima. Uz raznolike i intenzivne ukuse, Thunder pruža snažno iskustvo korisnicima. Dostupni u različitim jakostima, Thunder je za one koji traže snažan udarac.",
    keywords: "thunder snus, jake arome, Thunder Srbija",
  },
  {
    id: 12,
    img: require("./assets/images/voltCategory.webp"),
    title: "VOLT",
    color: "#1f1f1",
    desc: "za ljubitelje neutralnih aroma",
    descEN: "For neutral flavor enthusiasts.",
    meta_description:
      "Otkrijte neutralne arome sa Volt nikotinskim kesicama. Blagi i uravnoteženi ukusi pružaju diskretno iskustvo. Sa različitim opcijama, Volt nudi suptilnu promenu bez previše jakih nota.",
    keywords: "volt snus,  Volt Srbija, nikotinskim kesicama",
  },
  {
    id: 13, // Pretpostavljeni ID za kategoriju ZYN
    img: require("./assets/images/images/zynCategory.png"),
    title: "ZYN",
    color: "#45aaf2", // Pretpostavljena boja za ZYN kategoriju
    desc: "za ljubitelje osvežavajućih i intenzivnih ukusa",
    descEN: "For enthusiasts of refreshing and intense flavors.",
    meta_description:
      "Upoznajte se sa ZYN nikotinskim vrećicama, dizajniranim da pruže jedinstveno iskustvo sa svojim osvežavajućim i intenzivnim ukusima. Savršene za one koji traže jake i dugotrajne senzacije.",
    keywords: "ZYN snus, ZYN Srbija, nikotinske vrećice",
  },
  {
    id: 14, // Pretpostavljeni ID za kategoriju CUBA
    img: require("./assets/images/images/cubaCategory.webp"),
    title: "CUBA",
    color: "#000",
    desc: "Za ljubitelje bogatih i voćnih ukusa.",
    descEN: "For fans of rich and fruity flavors.",
    meta_description:
      "Uživajte u CUBA nikotinskim vrećicama koje donose bogate i voćne ukuse sa intenzivnim doživljajem. Savršene za one koji traže egzotične i dugotrajne senzacije.",
    keywords: "CUBA snus, CUBA Srbija, nikotinske vrećice",
  },
  {
    id: 15,
    img: require("./assets/images/images/white-fox-category.png"),
    title: "WHITE FOX",
    color: "#FFFFFF",
    desc: "Za ljubitelje čistih, osvežavajućih i mentolnih ukusa.",
    descEN: "For enthusiasts of clean, refreshing, and minty flavors.",
    meta_description:
      "Otkrijte WHITE FOX nikotinske vrećice sa čistim, osvežavajućim ukusima i jakim nikotinskim udarcima. Dizajnirane za one koji traže svežinu i intenzivnost.",
    keywords: "WHITE FOX snus, WHITE FOX Srbija, nikotinske vrećice",
  },
  {
    id: 16,
    img: require("./assets/images/camoCategory.jpeg"),
    title: "CAMO",
    color: "#A4C639",
    desc: "Za one koji traže osvežavajuće ukuse i balansiranu jačinu.",
    descEN: "For those seeking refreshing flavors and balanced strength.",
    meta_description:
      "Otkrijte CAMO nikotinske vrećice sa osvežavajućim i inovativnim ukusima. Idealne za svakodnevnu upotrebu i ljubitelje diskretnog uživanja.",
    keywords: "CAMO snus, CAMO Srbija, nikotinske vrećice",
  },
  {
    id: 17,
    img: require("./assets/images/77Category.webp"),
    title: "77",
    color: "#FF5252",
    desc: "Raznovrsni ukusi za svakodnevno uživanje u nikotinskim vrećicama.",
    descEN: "A variety of flavors for everyday enjoyment of nicotine pouches.",
    meta_description:
      "Otkrijte 77 nikotinske vrećice sa širokim spektrom ukusa. Idealne za one koji traže kvalitet i raznovrsnost u svakom trenutku.",
    keywords: "77 snus, 77 Srbija, nikotinske vrećice",
  },
];

export const seo_products = [
  {
    id: 1,
    title: "Mighty Peppermint",
    category: "VELO",
    meta_description:
      "Osetite osvežavajuću moć Velo Mighty Peppermint-a! Naša specijalno kreirana mešavina donosi trenutni osećaj svežine, idealan za one momente kada vam je potreban brz osvežavajući dah. Sa intenzivnim ukusom nane i dugotrajnim dejstvom, Velo Mighty Peppermint je vaš pouzdani saputnik za svež dah u svakoj prilici.",
    cover_img: require(`./assets/images/products/velo-mighty-peppermint.webp`),
    keywords: "",
  },
  {
    id: 2,
    title: "X Freeze Ultra",
    category: "VELO",
    meta_description:
      "Uživajte u bezvremenskom ukusu mente uz VELO X Freeze Ultra nikotinske jastučiće. Pronađite ih na našoj stranici snus prodaja i uživajte u besplatnoj dostavi za sve narudžbe!",
    cover_img: require("./assets/images/products/velo-x-freeze-ultra.webp"),
    keywords: "snus prodaja",
  },
  {
    id: 3,
    title: "Ice Cool Slim",
    category: "VELO",
    meta_description:
      "Upoznajte Velo Crispy Peppermint - vaš novi saveznik u održavanju svežeg daha! Ovaj proizvod kombinuje živahni ukus nane sa jedinstvenom hrskavom teksturkom, pružajući trenutnu eksploziju svežine.",
    cover_img: require("./assets/images/products/velo-ice-cool-slim.webp"),
    keywords: "snus prodaja",
  },
  {
    id: 4,
    title: "Ice Cold",
    category: "PABLO",
    meta_description:
      "Osvežite se uz PABLO Ice Cold nikotinske kesice sa hladnim ukusom i visokim sadržajem nikotina. Bez duvana i neželjenih mrlja na zubima. Kupite ih odmah na našoj stranici - snus prodaja",
    cover_img: require("./assets/images/products/pablo-ice-cold.webp"),
    keywords: "snus prodaja",
  },
  {
    id: 5,
    title: "Extreme Edition",
    category: "GARANT",
    meta_description:
      "Isprobajte našu novu Extreme Edition nikotinsku kesicu sa visokim sadržajem nikotina i intenzivnim ukusom. Ne preporučuje se početnicima. Kupite odmah na našoj stranici - snus prodaja",
    cover_img: require("./assets/images/products/grant-extreme-edition.webp"),
    keywords: "snus prodaja",
  },
  {
    id: 6,
    title: "Mighty Peppermint MAX",
    category: "VELO",
    meta_description:
      "Iskusite vrhunsku svežinu i jaku nikotinsku snagu sa VELO Mighty Peppermint MAX. Ovaj inovativni nikotinski proizvod nudi intenzivan mentolasti ukus bez dima i pepela, idealan za korišćenje u svakoj situaciji. Pređite na čistiju alternativu pušenju sa VELO i uživajte u diskretnom, praktičnom načinu zadovoljenja vaših nikotinskih potreba. Savršeno za korisnike koji traže snažno iskustvo bez kompromisa.",
    cover_img: require("./assets/images/products/velo-mighty-peppermint-max.webp"),
    keywords: "snus prodaja",
  },
  {
    id: 7,
    title: "Cool Storm Slim X-Strong",
    category: "VELO",
    meta_description:
      "Energizirajte se uz Velo Cool Storm Slim X-Strong nikotinske kesice sa visokim sadržajem nikotina. Osetite snažan i dugotrajan ukus mente koji će vas osvežiti bez duvana. Kupite odmah na našoj stranici - snus prodaja",
    cover_img: require("./assets/images/products/velo-cool-storm-slim-x-strong.webp"),
    keywords: "snus prodaja",
  },
  {
    id: 8,
    title: "Spicy Pineapple Slim Strong",
    category: "VELO",
    meta_description:
      "Probudite svoja čula uz Velo Spicy Pineapple Slim Strong nikotinske jastučiće sa snažnim ukusom ananasa i blagim pikantnim začinima. Bez duvana i neugodnih mirisa. Kupite ih odmah na našoj stranici - snus prodaja.",
    cover_img: require("./assets/images/products/velo-spicy-pineapple-slim-strong.webp"),
    keywords: "snus prodaja",
  },
  {
    id: 9,
    title: "Blushy Berry",
    category: "VELO",
    meta_description:
      "VELO Blushy Berry nikotinske vrećice: Vaša dnevna doza zadovoljstva sa ukusom bobica. Bezdimno, diskretno, i spremno za uživanje u svakom trenutku.",
    cover_img: require("./assets/images/products/velo_blushy_berry.webp"),
    keywords: "snus prodaja",
  },
  {
    id: 10,
    title: "Mini Groovy Grape",
    category: "VELO",
    meta_description:
      "VELO Mini Groovy Grape nikotinske vrećice: Intenzivan ukus grožđa u diskretnom formatu. Savršeno za ljubitelje nikotina koji cene eleganciju i praktičnost.",
    cover_img: require("./assets/images/products/groovy_grape_mini.webp"),
    keywords: "nikotinske vrećice",
  },
  // novo
  {
    id: 11,
    title: "Ice Cool",
    category: "GARANT",
    meta_description:
      "Uživajte u osvežavajućem ukusu mente i snažnom osećaju nikotina uz GARANT Ice Cool nikotinski proizvod. Bez duvana i neprijatnih mirisa. Kupite ih odmah na našoj stranici - snus prodaja.",
    cover_img: require("./assets/images/products/grant-ice-cool.webp"),
    keywords: "snus prodaja",
  },
  {
    id: 12,
    title: "Mango Lime Extreme Edition",
    category: "GARANT",
    meta_description:
      "Isprobajte GARANT Mango Lime Extreme Edition nikotinski proizvod sa intenzivnim ukusom manga i limete. Uživajte u diskretnom i jednostavnom načinu konzumiranja nikotina bez duvana i neprijatnih mirisa. Kupite ih odmah na našoj stranici - snus prodaja.",
    cover_img: require("./assets/images/products/grant-mango-lime.webp"),
    keywords: "nikotinski proizvod, snus prodaja",
  },
  {
    id: 13,
    title: "Ice Peach Extreme Edition",
    category: "GARANT",
    meta_description:
      "Isprobajte GARANT Ice Peach Extreme Edition nikotinski proizvod sa osvežavajućim ukusom breskve i jakim osećajem nikotina. Narucite ih odmah na našoj snus online prodavnici.",
    cover_img: require("./assets/images/products/grant-ice-peach.webp"),
    keywords: "nikotinski proizvod, snus online",
  },
  {
    id: 14,
    title: "Ice Blueberry Extreme Edition",
    category: "GARANT",
    meta_description:
      "Isprobajte GARANT Ice Blueberry Extreme Edition nikotinski proizvod sa osvežavajućim ukusom borovnice i jakim osećajem nikotina. Snus na teritoriji cele Srbije.  Naručite ih odmah na našoj snus online prodavnici.",
    cover_img: require("./assets/images/products/grant-ice-blueberry.webp"),
    keywords: "snus online",
  },
  {
    id: 15,
    title: "Cool Air Slim Extra Strong",
    category: "LYFT",
    meta_description:
      "LYFT Cool Air Slim Extra Strong je inovativni proizvod koji pruža jak osvežavajući ukus mente i hladan osećaj",
    cover_img: require("./assets/images/products/lyft-cool-air-slim-extra-strong.webp"),
    keywords: "snus online, snus prodaja",
  },
  {
    id: 16,
    title: "Cool Air Slim ULTRA Strong",
    category: "LYFT",
    meta_description:
      "LYFT Cool Air Slim ULTRA Strong je najjači proizvod u Cool Air liniji, sa intenzivnim osvežavajućim ukusom mente i još jačim osećajem. Kupite ih odmah na našoj snus online prodavnici, snus prodaja.",
    cover_img: require("./assets/images/products/lyft-cool-air-slim-ultra-strong.webp"),
    keywords: "snus online, snus prodaja",
  },

  {
    id: 17,
    title: "Ice Cool Mint Hard Slim",
    category: "FEDRS",
    meta_description:
      "Isprobajte FEDRS Ice Cool Mint Hard Slim, inovativan nikotinski proizvod sa snažnim ukusom dvostruke mente i intenzivnim osećajem nikotina.Poručite ih odmah na našoj snus online prodavnici, snus prodaja.",
    cover_img: require("./assets/images/products/fedrs-ice-cool-mint.webp"),
    keywords: "nikotinski proizvod, snus online, snus prodaja",
  },
  {
    id: 18,
    title: "FEDRS Ice Cool Mint No.8 Extra Strong Slim",
    category: "FEDRS",
    meta_description:
      "Isprobajte FEDRS Ice Cool Mint No.8 Extra Strong Slim, nikotinski proizvod sa osvežavajućim ukusom mente i jakim osećajem nikotina. Bez duvana i sa diskretnim i jednostavnim načinom konzumiranja. Naručite ih odmah na našoj snus online prodavnici - snus prodaja.",
    cover_img: require("./assets/images/products/fedrs-ice-cool-mint-no8.webp"),
    keywords: "snus prodaja, nikotinski proizvod, snus online prodavnica",
  },
  {
    id: 19,
    title: "Black Slim Extreme",
    category: "ICEBERG",
    meta_description:
      "Isprobajte ICEBERG Black Slim Extreme, nikotinski proizvod sa jakim ukusom duvana i mentola. Neka vas preplavi osvežavajuća aroma i snažan osećaj nikotina. Kupite ga odmah na našoj online prodavnici snusa.",
    cover_img: require("./assets/images/products/iceberg-black-slim-extreme.webp"),
    keywords: "snus",
  },
  {
    id: 20,
    title: "Strawberry Banana Gum Slim Extreme",
    category: "ICEBERG",
    meta_description:
      "Isprobajte ICEBERG Strawberry Banana Gum Slim Extreme - inovativni nikotinski proizvod sa ukusom slatke jagode, banane i žvakaće gume. ",
    cover_img: require("./assets/images/products/iceberg-strawberry-banana-gum.webp"),
    keywords: "",
  },
  {
    id: 21,
    title: "Watermelon Mint Gum Slim Extreme",
    category: "ICEBERG",
    meta_description:
      "Isprobajte ICEBERG Watermelon Mint Gum Slim Extreme nikotinski proizvod sa ukusom lubenice i mente. Poručite odmah na našoj snus online prodavnici - snus prodaja.",
    cover_img: require("./assets/images/products/iceberg-watermelon-mint-gum.webp"),
    keywords: "nikotinski proizvod, snus prodaja",
  },
  {
    id: 22,
    title: "Raspberry Gum Slim Extreme",
    category: "ICEBERG",
    meta_description:
      "Isprobajte ICEBERG Raspberry Gum Slim Extreme - najnoviji dodatak asortimanu sa ukusom sveže maline i jakim osećajem nikotina. Uživajte u bezbednom i prijatnom iskustvu sa snus proizvodom.",
    cover_img: require("./assets/images/products/iceberg-raspberry.webp"),
    keywords: "snus online",
  },
  {
    id: 23,
    title: "Grape Gum Slim Extreme",
    category: "ICEBERG",
    meta_description:
      "Isprobajte ICEBERG Grape Gum Slim Extreme nikotinski proizvod sa jakim ukusom grožđa i ekstremnim osećajem nikotina. Naručite ovaj snus sa osvežavajućim ukusom grožđa putem naše snus online prodavnice i uživajte u svakom trenutku.",
    cover_img: require("./assets/images/products/iceberg-grape-gum-slim-extreme.webp"),
    keywords: "snus sa ukusom grožđa",
  },
  {
    id: 24,
    title: "Energy Slim Extra Strong",
    category: "ICEBERG",
    meta_description:
      "ICEBERG Energy Slim Extra Strong je nikotinski proizvod sa visokim nivoom kofeina i taurina koji pruža intenzivnu energiju i snažan osećaj nikotina. Isprobajte sada i uživajte u neverovatnom ukusu i energiji!",
    cover_img: require("./assets/images/products/iceberg-energy.webp"),
    keywords: "snus proizvod, snus ukus",
  },
  {
    id: 25,
    title: "Winter Chill Slim Extra Strong",
    category: "VELO",
    meta_description:
      "VELO Winter Chill Slim Extra Strong je nikotinski proizvod bez duvana koji pruža snažan osećaj nikotina i osvežavajući ukus mente. Isprobajte sada i uživajte u intenzivnom iskustvu VELO Winter Chill Slim Extra Strong - snus prodaja snus srbija!",
    cover_img: require("./assets/images/products/velo-winter-chill.webp"),
    keywords: "snus prodaja, nikotinski proizvod",
  },
  {
    id: 26,
    title: "Freeze Slim ULTRA",
    category: "VELO",
    meta_description:
      "Otkrijte ultimativno iskustvo nikotina sa VELO Mighty Peppermint ULTRA. Sa ultra jakom formulom i prodornim mentolom, VELO ULTRA pruža vrhunsku svežinu i zadovoljstvo bez dima. Idealno za one koji traže snažnu i dugotrajnu alternativu pušenju, bilo gde i bilo kad.",
    cover_img: require("./assets/images/products/velo-mighty-peppermint-ultra.webp"),
    keywords: "snus online",
  },
  {
    id: 27,
    title: "All White Portion Extremely Strong",
    category: "SIBERIA",
    meta_description:
      "SIBERIA All White Portion Extremely Strong je nikotinski proizvod bez duvana sa najjačom dozom nikotina koju možete pronaći na tržištu. Isprobajte sada i osetite neverovatno snažan osećaj nikotina uz osvežavajući ukus mente!",
    cover_img: require("./assets/images/products/siberia-all-white.webp"),
    keywords: "najjači nikotinski proizvod",
  },
  {
    id: 28,
    title: "Groovy Grape",
    category: "VELO",
    meta_description:
      "Otkrijte VELO Groovy Grape nikotinske vrećice: bogat ukus grožđa, bezdimno zadovoljstvo i diskretno iskustvo. Savršen izbor za ljubitelje nikotina koji cene kvalitet i eleganciju",
    cover_img: require("./assets/images/products/velo-groovy-grape.webp"),
    keywords: "snus serbia, nikotinski proizvod",
  },
  {
    id: 29,
    title: "Fresh Jalapeno",
    category: "VELO",
    meta_description:
      "VELO Fresh Jalapeno je nikotinski proizvod bez duvana koji kombinuje pikantni ukus jalapenosa sa snažnim osećajem nikotina. Isprobajte sada VELO Fresh Jalapeno i osvežite svoje čulo ukusa! Snus Serbia",
    cover_img: require("./assets/images/products/velo-fresh-jalapeno.webp"),
    keywords: "snus serbia",
  },
  {
    id: 30,
    title: "Frosty Grapefruit",
    category: "VELO",
    meta_description:
      "VELO Frosty Grapefruit je nikotinski proizvod bez duvana koji pruža osvežavajući ukus grejpfruta i snažan osećaj nikotina. Isprobajte sada VELO Frosty Grapefruit i uživajte u harmoničnom spoju citrusa i nikotina!",
    cover_img: require("./assets/images/products/velo-frosty-grapefruit.webp"),
    keywords: "snus serbia, nikotinski proizvod, grejpfrut, citrusi",
  },
  {
    id: 31,
    title: "Elderflower Spritz",
    category: "VELO",
    meta_description:
      "VELO Elderflower Spritz je nikotinski proizvod bez duvana koji pruža osvežavajući ukus cveta bazge i snažan osećaj nikotina. Isprobajte sada VELO Elderflower Spritz i uživajte u neodoljivoj kombinaciji cvetnog ukusa i nikotina! snus prodaja beograd",
    cover_img: require("./assets/images/products/velo-elderflower-spritz.webp"),
    keywords: ", snus beograd, cvetni ukus",
  },
  {
    id: 32,
    title: "Black Currant",
    category: "LYFT",
    meta_description:
      "LYFT Black Currant je nikotinski proizvod bez duvana koji pruža ukus zrele crne ribizle i snažan osećaj nikotina. Isprobajte sada LYFT Black Currant i uživajte u intenzivnom voćnom ukusu uz nikotinski stimulans!",
    cover_img: require("./assets/images/products/lyft-black-currant.webp"),
    keywords: "crna ribizla, voćni ukus, nikotin",
  },
  {
    id: 33,
    title: "Breezy Mango Slim",
    category: "VELO",
    meta_description:
      "Iskusite osveženje manga sa Velo Breezy Mango Slim nikotinskim vrećicama. Bezdimno, diskretno, sa savršenom dozom nikotina. Idealno za modernog korisnika koji teži kvalitetu i stilu.",
    cover_img: require("./assets/images/products/velo-breezy-mango.webp"),
    keywords: "snus srbija",
  },
  {
    id: 34,
    title: "Maradona",
    category: "FEDRS",
    meta_description:
      "Isprobajte FEDRS Maradona nikotinski proizvod posvećen fudbalskoj legendi Dijegu Maradoni. Uživajte u jedinstvenom ukusu i snažnom osećaju nikotina. Kupite ovaj poseban snus proizvod sada i odaćemo počast nezaboravnom Maradoni!",
    cover_img: require("./assets/images/products/fedrs-maradona.webp"),
    keywords: "fudbalska legenda, snus online prodavnica",
  },
  {
    id: 35,
    title: "Dragon Fire",
    category: "ICEBERG",
    meta_description:
      "Isprobajte ICEBERG Dragon Fire nikotinski proizvod sa intenzivnim ukusom voća i začinjenom notom. Osetite snažan osećaj nikotina i uživajte u eksploziji ukusa. Kupite ovaj poseban snus proizvod sada i otkrijte novu dimenziju ukusa!",
    cover_img: require("./assets/images/products/iceberg-dragon-fire.webp"),
    keywords: "nikotinski proizvod, voćni ukus",
  },
  {
    id: 36,
    title: "Kiwi Strawberry",
    category: "ICEBERG",
    meta_description:
      "Uživajte u savršenoj kombinaciji voćnog ukusa kiwija i jagode sa ICEBERG Kiwi Strawberry nikotinskim proizvodom. Bez duvana i neprijatnih mirisa. Kupite ovaj proizvod sada i otkrijte novu dimenziju ukusa!",
    cover_img: require("./assets/images/products/iceberg-kiwi-strawberry.webp"),
    keywords: "snus beograd",
  },
  {
    id: 37,
    title: "Menthol",
    category: "ICEBERG",
    meta_description:
      "Uživajte u osvežavajućem ukusu mente i jakom osećaju nikotina sa ICEBERG Menthol nikotinskim proizvodom. Bez duvana i neprijatnih mirisa. Kupite ovaj proizvod sada i doživite trenutke svežine.",
    cover_img: require("./assets/images/products/iceberg-menthol.webp"),
    keywords: "snus belgrade, osvežavajući ukus",
  },
  {
    id: 38,
    title: "Arasaka",
    category: "ICEBERG",
    meta_description:
      "Otkrijte futuristički ukus sa ICEBERG Arasaka nikotinskim proizvodom. Uživajte u jedinstvenoj kombinaciji ukusa i snažnom osećaju nikotina. Poručite ovaj proizvod sada i istražite novu dimenziju ukusa!",
    cover_img: require("./assets/images/products/iceberg-arasaka.webp"),
    keywords: "snus serbia",
  },
  {
    id: 39,
    title: "Ninja Orange",
    category: "ICEBERG",
    meta_description:
      "Probajte hrabro voće sa ICEBERG Ninja Orange nikotinskim proizvodom. Uživajte u svežem ukusu narandže i intenzivnom osećaju nikotina. Kupite ovaj proizvod sada i uđite u svet osveženja!",
    cover_img: require("./assets/images/products/iceberg-ninja-orange.webp"),
    keywords: "nikotinski proizvodi",
  },
  {
    id: 40,
    title: "Double Apple",
    category: "ICEBERG",
    meta_description:
      "Osetite harmoniju ukusa jabuke sa ICEBERG Double Apple nikotinskim proizvodom. Uživajte u svežem i slatkom ukusu jabuke i snažnom osećaju nikotina. Poručite ovaj proizvod sada i doživite dvostruko zadovoljstvo!",
    cover_img: require("./assets/images/products/iceberg-double-apple.webp"),
    keywords: "snus online",
  },
  {
    id: 41,
    title: "Cola",
    category: "ICEBERG",
    meta_description:
      "Doživite osveženje uz ICEBERG Cola nikotinski proizvod sa ukusom vašeg omiljenog gaziranog pića. Uživajte u slatkom ukusu kole i jakom osećaju nikotina. Kupite ovaj proizvod sada i osvežite se na novi način!",
    cover_img: require("./assets/images/products/iceberg-cola.webp"),
    keywords: "snus online",
  },
  {
    id: 42,
    title: "Ice Cold XXL",
    category: "PABLO",
    meta_description:
      "Uživajte u ledeno svežem osećaju sa PABLO Ice Cold XXL nikotinskim proizvodom. Sa jakim hladnim ukusom, ovaj proizvod će vas rashladiti i osvežiti. Kupite ovaj proizvod sada i doživite ekstremnu svežinu!",
    cover_img: require("./assets/images/products/pablo-ice-cold-xxl.webp"),
    keywords: "snus online prodavnica",
  },
  {
    id: 43,
    title: "Frosted Mint",
    category: "PABLO",
    meta_description:
      "Isprobajte PABLO Frosted Mint nikotinski proizvod sa hladnim i osvežavajućim ukusom mente. Uživajte u snažnom osećaju nikotina i diskretnom načinu konzumiranja. Kupite ovaj proizvod sada i doživite intenzivnu svežinu!",
    cover_img: require("./assets/images/products/pablo-frosted-mint.webp"),
    keywords: "snus online prodavnica",
  },
  {
    id: 44,
    title: "Fatality Brutal Fruity Gum",
    category: "KURWA",
    meta_description:
      "Isprobajte Kurwa Fatality Brutal Fruity Gum nikotinski proizvod sa ekstremno voćnim ukusom i intenzivnim osećajem nikotina. Uživajte u brutalnom iskustvu i diskretnom načinu konzumiranja. Kupite ovaj proizvod sada i doživite voćnu eksploziju!",
    cover_img: require("./assets/images/products/kurwa-fatality-brutal-fruity-gum.webp"),
    keywords: "KURWA",
  },
  {
    id: 45,
    title: "Blackcurrant Purple Grape",
    category: "KURWA",
    meta_description:
      "Osetite dubok i intenzivan ukus crne ribizle i ljubičaste grožđice sa Kurwa Blackcurrant Purple Grape nikotinskim proizvodom. Uživajte u jedinstvenoj kombinaciji i snažnom osećaju nikotina. Kupite ovaj proizvod sada i istražite voćni raj!",
    cover_img: require("./assets/images/products/kurwa-blackcurrant-purple-grape.webp"),
    keywords: "snus ukusi, snus prodavnica",
  },
  // 10.10.2023.
  {
    id: 46,
    title: "Bubblegum",
    category: "KURWA",
    meta_description:
      "Osetite slatki i živahni ukus žvakaće gume sa Kurwa Bubblegum nikotinskim proizvodom. Uživajte u jedinstvenoj kombinaciji i osvežavajućem osećaju nikotina. Kupite ovaj proizvod sada i istražite svet žvakaće gume!",
    cover_img: require("./assets/images/products/kurwa-bubblegum.webp"),
    keywords: "žvakaća guma, snus povoljno",
  },
  {
    id: 47,
    title: "Grape",
    category: "ICEBERG",
    meta_description:
      "Iskusite osvežavajući i intenzivan ukus grožđa sa Iceberg Grape nikotinskim proizvodom. Uživajte u jedinstvenoj kombinaciji i snažnom osećaju nikotina. Nabavite ovaj proizvod odmah i otkrijte voćnu eksploziju!",
    cover_img: require("./assets/images/products/iceberg-grape.webp"),
    keywords: "snus beograd",
  },
  {
    id: 48,
    title: "Crazy Mix",
    category: "ICEBERG",
    meta_description:
      "Probajte šareni miks ukusa sa Iceberg Crazy Mix nikotinskim proizvodom. Uživajte u jedinstvenoj kombinaciji i osvežavajućem osećaju nikotina. Kupite ovaj proizvod sada i istražite raznovrsne voćne arome!",
    cover_img: require("./assets/images/products/iceberg-crazy-mix.webp"),
    keywords: "snus Novi Sad, voćni mix",
  },
  {
    id: 49,
    title: "Coconut",
    category: "ICEBERG",
    meta_description:
      "Osetite egzotični ukus kokosa sa Iceberg Coconut nikotinskim proizvodom. Uživajte u jedinstvenoj kombinaciji i osvežavajućem osećaju nikotina. Nabavite ovaj proizvod odmah i putujte ka tropskom raju!",
    cover_img: require("./assets/images/products/iceberg-coconut.webp"),
    keywords: "snus na malo, kokos",
  },

  {
    id: 50,
    title: "Bubblegum",
    category: "ICEBERG",
    meta_description:
      "Iskusite slatki i živahni ukus žvakaće gume sa Iceberg Bubblegum nikotinskim proizvodom. Uživajte u jedinstvenoj kombinaciji i osvežavajućem osećaju nikotina. Kupite ovaj proizvod sada i doživite svet žvakaće gume!",
    cover_img: require("./assets/images/products/iceberg-bubblegum.webp"),
    keywords: "žvakaća guma, online snus",
  },
  {
    id: 51,
    title: "Dragon Fruit",
    category: "ICEBERG",
    meta_description:
      "Iskusite egzotični ukus pitaje voća sa Iceberg Dragon Fruit nikotinskim proizvodom. Uživajte u jedinstvenoj kombinaciji i osvežavajućem osećaju nikotina. Nabavite ovaj proizvod odmah i otputujte u voćnu avanturu!",
    cover_img: require("./assets/images/products/iceberg-dragon-fruit.webp"),
    keywords: "snus voće",
  },
  {
    id: 52,
    title: "Strawberry Mango Gum",
    category: "ICEBERG",
    meta_description:
      "Osetite slatko-voćni miks jagode i manga sa Iceberg Strawberry Mango Gum nikotinskim proizvodom. Uživajte u jedinstvenoj kombinaciji i osvežavajućem osećaju nikotina. Kupite ovaj proizvod sada i doživite voćni eksploziju!",
    cover_img: require("./assets/images/products/iceberg-strawberry-mango-gum.webp"),
    keywords: "jagoda, mango, žvakaća guma",
  },

  {
    id: 53,
    title: "Emerald",
    category: "ICEBERG",
    meta_description:
      "Iskusite osvežavajući ukus sa Iceberg Emerald nikotinskim proizvodom. Uživajte u jedinstvenoj kombinaciji i osvežavajućem osećaju nikotina. Nabavite ovaj proizvod odmah i doživite ledenu svežinu!",
    cover_img: require("./assets/images/products/iceberg-emerald.webp"),
    keywords: "svežina, nikotinski proizvod",
  },

  {
    id: 54,
    title: "Cold Mint",
    category: "KILLA",
    meta_description:
      "Iskusite intenzivan i hladan ukus mente sa Killa Cold Mint nikotinskim proizvodom. Nabavite ovaj proizvod odmah i osvežite svoje nepce!",
    cover_img: require("./assets/images/products/killa-cold-mint.webp"),
    keywords: "menta, nikotinski proizvod",
  },

  {
    id: 55,
    title: "Blue Ice",
    category: "RABBIT",
    meta_description:
      "Osetite osvežavajući ukus sa Rabbit Blue Ice nikotinskim proizvodom. Nabavite ovaj proizvod odmah i doživite ledenu eksploziju!",
    cover_img: require("./assets/images/products/rabbit-blue-ice.webp"),
    keywords: "svežina, nikotinski proizvod",
  },

  {
    id: 56,
    title: "Exclusive Banana Ice",
    category: "PABLO",
    meta_description:
      "Iskusite ekskluzivni ukus banane sa Pablo Exclusive Banana Ice nikotinskim proizvodom. Nabavite ovaj proizvod odmah i uživajte u jedinstvenoj voćnoj kombinaciji!",
    cover_img: require("./assets/images/products/pablo-banana-ice.webp"),
    keywords: "snus banana, ekskluzivni, pablo snus banana",
  },

  {
    id: 57,
    title: "Exclusive Mango Ice",
    category: "PABLO",
    meta_description:
      "Doživite intenzivni ukus manga sa Pablo Exclusive Mango Ice nikotinskim proizvodom. Nabavite ovaj proizvod odmah i uživajte u egzotičnom voćnom doživljaju!",
    cover_img: require("./assets/images/products/pablo-mango-ice.webp"),
    keywords: "snus mango, pablo snus mango",
  },
  {
    id: 58,
    title: "Dark Forest",
    category: "THUNDER",
    meta_description:
      "Doživite duboku notu šume sa Thunder Dark Forest nikotinskim proizvodom. Kupite online ovaj proizvod odmah i uživajte u intenzivnom doživljaju prirode!",
    cover_img: require("./assets/images/products/thunder-dark-forest.webp"),
    keywords: "snus šuma, intenzivan snus",
  },

  {
    id: 59,
    title: "Deep Freeze",
    category: "THUNDER",
    meta_description:
      "Iskusite intenzivni efekat leda sa Thunder Deep Freeze nikotinskim proizvodom. Kupite onlajn ovaj proizvod odmah i osvežite svoj ukus!",
    cover_img: require("./assets/images/products/thunder-deep-freeze.webp"),
    keywords: "led, snus osvežavajuće",
  },

  {
    id: 60,
    title: "Cinnamon Flame",
    category: "VELO",
    meta_description:
      "Osetite toplu notu cimeta sa VELO Cinnamon Flame nikotinskim proizvodom. Nabavite ovaj proizvod odmah i uživajte u blagom i toplo ukusu!",
    cover_img: require("./assets/images/products/velo-cinnamon-flame.webp"),
    keywords: "snus cimet, snus topao",
  },

  {
    id: 61,
    title: "McLaren Watermelon",
    category: "VELO",
    meta_description:
      "Iskusite ukus sočne lubenice sa VELO McLaren Watermelon nikotinskim proizvodom. Nabavite ovaj proizvod odmah i osvežite svoj ukus!",
    cover_img: require("./assets/images/products/velo-mclaren-watermelon.webp"),
    keywords: "snus lubenica, snus osvežavajuće",
  },

  {
    id: 62,
    title: "Deep Freeze",
    category: "VOLT",
    meta_description:
      "Doživite intenzivan efekat leda sa VOLT Deep Freeze nikotinskim proizvodom. Nabavite ovaj proizvod odmah i osvežite svoj ukus!",
    cover_img: require("./assets/images/products/volt-deep-freeze.webp"),
    keywords: "snus led",
  },
  {
    id: 63,
    title: "Icy Berries",
    category: "VELO",
    meta_description:
      "Otkrijte svežinu šumskih bobica sa VELO Icy Berries nikotinskim proizvodom. Nabavite ovaj osvežavajući proizvod odmah!",
    cover_img: require("./assets/images/products/velo-icy-berries.webp"),
    keywords: "snus šumske bobice, snus osvežavajuće, snus hladno",
  },
  {
    id: 64,
    title: "Orange Spark",
    category: "VELO",
    meta_description:
      "Doživite osvežavajuću eksploziju ukusa sa VELO Orange Spark, savršenim spojem slatke narandže i osvežavajućih citrusnih nota.",
    cover_img: require("./assets/images/products/velo_orange_spark.webp"),
    keywords: " narandža, osvežavajuće, citrus",
  },

  {
    id: 65,
    title: "Zest Flame",
    category: "VELO",
    meta_description:
      "Pronađite savršeni balans između vatrenih začina i osvežavajućih citrusnih aroma sa VELO Zest Flame.",
    cover_img: require("./assets/images/products/velo_zest_flame.webp"),
    keywords: "citrus, začinjeno, intenzivno",
  },

  {
    id: 66,
    title: "Lime Flame",
    category: "VELO",
    meta_description:
      "Uživajte u jedinstvenoj kombinaciji osvežavajuće limete i blagih začinskih nota sa VELO Lime Flame.",
    cover_img: require("./assets/images/products/velo_lime_flame.webp"),
    keywords: " limeta, osvežavajuće, blago začinjeno",
  },

  {
    id: 67,
    title: "Mango Flame",
    category: "VELO",
    meta_description:
      "Iskusite tropsku slatkoću manga sa začinskim obrtom uz VELO Mango Flame.",
    cover_img: require("./assets/images/products/velo_mango_flame.webp"),
    keywords: " mango, tropsko, slatko, začinjeno",
  },
  {
    id: 68,
    title: "Extreme Raspberry",
    category: "GARANT",
    meta_description:
      "Otkrijte intenzivnu aromu maline sa Garant Extreme Raspberry nikotinskim vrećicama.",
    cover_img: require("./assets/images/products/grant_extreme_raspberry.webp"),
    keywords: "malina, intenzivno, voćno, osvežavajuće",
  },

  {
    id: 69,
    title: "Extreme Cherry",
    category: "GARANT",
    meta_description:
      "Zaronite u duboki ukus višnje sa Garant Extreme Cherry nikotinskim vrećicama.",
    cover_img: require("./assets/images/products/grant_extreme_cherry.webp"),
    keywords: "višnja, bogato, intenzivno, voćno",
  },

  {
    id: 70,
    title: "Extreme Grape",
    category: "GARANT",
    meta_description:
      "Iskusite bogatstvo ukusa grožđa sa Garant Extreme Grape nikotinskim vrećicama.",
    cover_img: require("./assets/images/products/grant_extreme_grape.webp"),
    keywords: "grožđe, bogato, voćno, nikotin",
  },

  {
    id: 71,
    title: "Extreme Energy Drink",
    category: "GARANT",
    meta_description:
      "Probudite se sa energizirajućim ukusom energetskog pića uz Garant Extreme Energy Drink vrećice.",
    cover_img: require("./assets/images/products/grant_extreme_energy_drink.webp"),
    keywords: "energetski napitak, osvežavajuće, stimulativno, intenzivno",
  },

  {
    id: 72,
    title: "Tangled Berry",
    category: "VELO",
    meta_description:
      "Uživajte u sočnoj mešavini bobica sa Velo Tangled Berry nikotinskim vrećicama.",
    cover_img: require("./assets/images/products/velo_tangled_berry.webp"),
    keywords: "bobičasto voće, slatko, voćno, osvežavajuće",
  },

  {
    id: 73,
    title: "Cool Mint Slim Extreme Strong",
    category: "ZYN",
    meta_description:
      "Doživite ekstremnu svežinu mente sa ZYN Cool Mint Slim Extreme Strong nikotinskim vrećicama.",
    cover_img: require("./assets/images/products/zyn_cool_mint_extreme_strong.webp"),
    keywords: "menta, sveže, ekstremno, jaka",
  },
  {
    id: 74,
    title: "Twisted Pineapple",
    category: "VELO",
    meta_description:
      "Uživajte u tropskom ukusu ananasa sa Velo Twisted Pineapple nikotinskim vrećicama.",
    cover_img: require("./assets/images/products/VELO-TwistedPineapple_Top-2400x2400.webp"),
    keywords: "ukus ananasa, tropski",
  },
  {
    id: 75,
    title: "Wintery Waterlemon Mini",
    category: "VELO",
    meta_description:
      "Osetite osvežavajuću kombinaciju lubenice i hladnog podtona sa Velo Wintery Waterlemon Mini.",
    cover_img: require("./assets/images/products/VELO-WinteryWatermelon_Top-2400x2400.webp"),
    keywords: "lubenica, blagi ukus, zimski",
  },
  {
    id: 76,
    title: "Breezy Mango High Nic",
    category: "VELO",
    meta_description:
      "Iskusite snažan nikotinski užitak uz neodoljivi ukus zrelog manga sa Velo Breezy Mango High Nic.",
    cover_img: require("./assets/images/products/VELO-BreezyMango_Top-2400x2400.webp"),
    keywords: "mango, visoki nikotin, osvežavajući",
  },
  {
    id: 77,
    title: "Icy Cherry",
    category: "VELO",
    meta_description:
      "Uživajte u osvežavajućem ukusu trešnje sa ledenim efektom sa Velo Icy Cherry.",
    cover_img: require("./assets/images/products/VELO-Icy-Cherry_Top-2400x2400.webp"),
    keywords: "trešnja, led, osvežavajući",
  },
  {
    id: 78,
    title: "Black Watermelon",
    category: "CUBA",
    meta_description:
      "Cuba Black Watermelon pruža bogat i sladak ukus lubenice sa tamnim tonovima.",
    cover_img: require("./assets/images/products/cuba-black-watermelon-front.webp"),
    keywords: "lubenica, voćni, intenzivan",
  },
  {
    id: 79,
    title: "All White Portion",
    category: "WHITE FOX",
    meta_description:
      "White Fox All White Portion nudi čisto nikotinsko iskustvo sa osvežavajućim mentolom.",
    cover_img: require("./assets/images/products/white_fox_all_white.webp"),
    keywords: "mentol, osvežavajuće, čisto",
  },
  {
    id: 80,
    title: "Black Edition",
    category: "WHITE FOX",
    meta_description:
      "White Fox Black Edition donosi snažno nikotinsko iskustvo sa bogatim i punim ukusom.",
    cover_img: require("./assets/images/products/white-fox-black72_medium.webp"),
    keywords: "intenzivno, bogato, crno",
  },
  {
    id: 81,
    title: "Full Charge",
    category: "WHITE FOX",
    meta_description:
      "White Fox Full Charge pruža snažan mentolski ukus sa visokim sadržajem nikotina.",
    cover_img: require("./assets/images/products/white_fox_full_charge_medium.webp"),
    keywords: "mentol, snažno, nikotin",
  },
  {
    id: 82,
    title: "Peppered Mint",
    category: "WHITE FOX",
    meta_description:
      "White Fox Peppered Mint pruža osvežavajući i papreni ukus nane sa intenzivnim nikotinskim udarcem.",
    cover_img: require("./assets/images/products/white_fox_peppermint.webp"),
    keywords: "nana, papreno, mentol",
  },
  {
    id: 83,
    title: "Black Cola",
    category: "CUBA",
    meta_description:
      "Cuba Black Cola donosi autentičan i osvežavajući ukus kole sa blagim tonovima karamele i citrusa.",
    cover_img: require("./assets/images/products/cuba-black-cola.webp"),
    keywords: "kola, osvežavajući, klasičan",
  },
  {
    id: 83,
    title: "Black Cherry Slim",
    category: "CUBA",
    meta_description:
      "Cuba Black Cherry Slim nudi intenzivan i sočan ukus crne višnje sa notama zrelog voća.",
    cover_img: require("./assets/images/products/cuba-black-cherry-slim.webp"),
    keywords: "višnja, voćni, sočan",
  },
  {
    id: 84,
    title: "Black Peach",
    category: "CUBA",
    meta_description:
      "Cuba Black Peach pruža sočan i sladak ukus zrele breskve sa blagim notama voćnog osveženja.",
    cover_img: require("./assets/images/products/cuba-black-peach.webp"),
    keywords: "breskva, slatko, osvežavajuće",
  },
  {
    id: 85,
    title: "Black Pineapple",
    category: "CUBA",
    meta_description:
      "Cuba Black Pineapple donosi tropsku eksploziju ukusa sa intenzivnim i osvežavajućim notama zrelog ananasa.",
    cover_img: require("./assets/images/products/cuba-black-pineapple.webp"),
    keywords: "ananasa, tropski, osvežavajući",
  },
  {
    id: 86,
    title: "Black Currant",
    category: "CUBA",
    meta_description:
      "Cuba Black Currant donosi bogat i osvežavajući ukus crne ribizle sa laganim kiselkastim notama.",
    cover_img: require("./assets/images/products/cuba-black-currant.webp"),
    keywords: "crna ribizla, voćni, osvežavajuće",
  },
  {
    id: 87,
    title: "Black Blueberry",
    category: "CUBA",
    meta_description:
      "Cuba Black Blueberry donosi zavodljiv ukus zrelih borovnica sa blagim tonovima prirodne slatkoće i osvežavajuće kiselosti.",
    cover_img: require("./assets/images/products/cuba-black-blueberry.webp"),
    keywords: "borovnica, voćni, sladak",
  },
  {
    id: 88,
    title: "Ninja Energy",
    category: "CUBA",
    meta_description:
      "Cuba Ninja Energy donosi snažnu i osvežavajuću aromu inspirisanu popularnim energetskim napicima.",
    cover_img: require("./assets/images/products/cuba-ninja-energy-front.webp"),
    keywords: "energetski, osvežavajuće, snažno",
  },
  {
    id: 89,
    title: "Ice Cold Mint",
    category: "CAMO",
    meta_description:
      "CAMO Ice Cold Mint pruža intenzivno osvežavajuće iskustvo sa savršenim spojem mentola i ledenih tonova.",
    cover_img: require("./assets/images/products/Camo-Ice-Cold-Mint-6mg.webp"),
    keywords: "menta, osvežavajuće, ledeno",
  },
  {
    id: 90,
    title: "Wild Mint",
    category: "CAMO",
    meta_description:
      "CAMO Wild Mint donosi jedinstvenu kombinaciju divljeg mentola i prirodnih tonova, pružajući osvežavajuće i bogato iskustvo.",
    cover_img: require("./assets/images/products/Camo-Wild-Mint-6mg.webp"),
    keywords: "menta, divlji, prirodni",
  },
  {
    id: 91,
    title: "Peppermint",
    category: "CAMO",
    meta_description:
      "CAMO Peppermint pruža nežnu, ali osvežavajuću kombinaciju slatkog i aromatičnog peperminta.",
    cover_img: require("./assets/images/products/Camo-Peppermint-6mg.webp"),
    keywords: "pepermint, osvežavajuće, slatko",
  },
  {
    id: 92,
    title: "Spicy Citrus",
    category: "CAMO",
    meta_description:
      "CAMO Spicy Citrus spaja osvežavajuće citrusne note sa toplim začinskim tonovima.",
    cover_img: require("./assets/images/products/Camo-Spicy-Citrus-6mg.webp"),
    keywords: "citrus, začinsko, osvežavajuće",
  },
  {
    id: 93,
    title: "Cola",
    category: "RABBIT",
    meta_description:
      "Rabbit Cola donosi bogat i autentičan ukus klasične kole, savršen za ljubitelje tradicionalnih i osvežavajućih aroma.",
    cover_img: require("./assets/images/products/rabbit-cola-front.webp"),
    keywords: "kola, tradicionalno, osvežavajuće",
  },
  {
    id: 94,
    title: "Pepper Mint Extreme",
    category: "RABBIT",
    meta_description:
      "RaBBiT Pepper Mint Extreme kombinuje osvežavajuću mentu sa toplim začinskim tonovima za jedinstveno i snažno iskustvo.",
    cover_img: require("./assets/images/products/rabbit-pepper-mint-extreme-front.webp"),
    keywords: "menta, začinsko, intenzivno",
  },
  {
    id: 95,
    title: "Tropical Mint Slim Medium",
    category: "77",
    meta_description:
      "77 Tropical Mint Slim Medium Nicotine Pouches donosi ukusnu kombinaciju tropskih nota i osvežavajuće mente. Idealno za one koji traže zdraviju alternativu.",
    cover_img: require("./assets/images/products/77-tropical-mint-medium-front.webp"),
    keywords: "tropska menta, nikotin, bez duvana",
  },
  {
    id: 96,
    title: "Cola Cherry Medium",
    category: "77",
    meta_description:
      "77 Cola Cherry Medium kombinuje osvežavajuću aromu kole sa bogatim ukusom višnje.",
    cover_img: require("./assets/images/products/77-cola-cherry-medium-front.webp"),
    keywords: "kola, višnja, nikotin, osvežavajuće",
  },
  {
    id: 97,
    title: "Cola Vanilla Medium",
    category: "77",
    meta_description:
      "77 Cola Vanilla Medium donosi kremastu kombinaciju vanile i klasične kole.",
    cover_img: require("./assets/images/products/77-cola-vanilla-medium-front.webp"),
    keywords: "kola, vanila, nikotin, osvežavajuće",
  },
  {
    id: 98,
    title: "Watermelon Ice Medium",
    category: "77",
    meta_description:
      "77 Watermelon Ice Medium spaja slatki ukus lubenice sa ledenim osveženjem.",
    cover_img: require("./assets/images/products/77-watermelon-ice-medium-front.webp"),
    keywords: "lubenica, ledeno, nikotin, osvežavajuće",
  },
  {
    id: 99,
    title: "Energy Mango",
    category: "Iceberg",
    meta_description:
      "Iceberg Energy Mango kombinuje slatkoću manga sa energizirajućim tonovima za osveženje.",
    cover_img: require("./assets/images/products/iceberg-energy-mango-front.webp"),
    keywords: "mango, energija, nikotin, egzotično",
  },
  {
    id: 100,
    title: "Grapefruit Pineapple",
    category: "Iceberg",
    meta_description:
      "Iceberg Grapefruit Pineapple donosi harmoničan spoj grejpa i ananasa za jedinstveno iskustvo.",
    cover_img: require("./assets/images/products/iceberg-grapefruit-pineapple-front.webp"),
    keywords: "grejp, ananas, nikotin, osvežavajuće",
  },
  {
    id: 101,
    title: "Exclusive Grape Ice",
    category: "PABLO",
    meta_description:
      "Exclusive Grape Ice donosi intenzivan ukus grožđa sa osvežavajućim tonovima leda.",
    cover_img: require("./assets/images/products/pablo-exclusive-grape-ice-front.webp"),
    keywords: "grožđe, led, nikotin, osvežavajuće, snussrbija, online prodaja",
  },
  {
    id: 102,
    title: "Exclusive Kiwi",
    category: "PABLO",
    meta_description:
      "Exclusive Kiwi nudi osvežavajuću kombinaciju ukusa kivija za jedinstveno iskustvo.",
    cover_img: require("./assets/images/products/pablo-exclusive-kiwi-front.webp"),
    keywords: "kivi, osvežavajuće, nikotin, voćno, snussrbija, online prodaja",
  },
  {
    id: 103,
    title: "Exclusive Passion Fruit",
    category: "PABLO",
    meta_description:
      "Exclusive Passion Fruit pruža egzotičan i osvežavajući ukus marakuje.",
    cover_img: require("./assets/images/products/pablo-exclusive-passion-fruit-front.webp"),
    keywords:
      "marakuja, egzotično, osvežavajuće, nikotin, snussrbija, online prodaja",
  },
  {
    id: 104,
    title: "Exclusive Strawberry Lychee",
    category: "PABLO",
    meta_description:
      "Exclusive Strawberry Lychee spaja bogat ukus jagode i egzotičnog ličija.",
    cover_img: require("./assets/images/products/pablo-exclusive-strawberry-lychee-front.webp"),
    keywords:
      "jagoda, liči, egzotično, nikotin, osvežavajuće, snussrbija, online prodaja",
  },
  {
    id: 105,
    title: "Black Banana Hit",
    category: "CUBA",
    meta_description:
      "Black Banana Hit nudi unikatnu kombinaciju crne banane i bogatih aroma.",
    cover_img: require("./assets/images/products/cuba-black-banana-hit-front.webp"),
    keywords: "banana, bogato, intenzivno, nikotin, snussrbija, online prodaja",
  },
  {
    id: 106,
    title: "Black Ice Spearmint",
    category: "CUBA",
    meta_description:
      "Black Ice Spearmint donosi osvežavajuću kombinaciju spearmint mente i ledenih tonova.",
    cover_img: require("./assets/images/products/cuba-black-ice-spearmint-front.webp"),
    keywords:
      "spearmint, led, osvežavajuće, nikotin, snussrbija, online prodaja",
  },
  {
    id: 107,
    title: "Coca Leaf",
    category: "CUBA",
    meta_description:
      "Coca Leaf donosi autentičan ukus listova koke za intenzivno iskustvo.",
    cover_img: require("./assets/images/products/cuba-coca-leaf-front.webp"),
    keywords:
      "koka, autentično, intenzivno, nikotin, snussrbija, online prodaja",
  },
  {
    id: 108,
    title: "Ninja Ice Cool",
    category: "CUBA",
    meta_description:
      "Ninja Ice Cool kombinuje osvežavajuće arome sa intenzivnim efektom hlađenja.",
    cover_img: require("./assets/images/products/cuba-ninja-ice-cool-front.jpg"),
    keywords:
      "hladno, osvežavajuće, nikotin, intenzivno, snussrbija, online prodaja",
  },
  {
    id: 109,
    title: "Black Double Fresh",
    category: "CUBA",
    meta_description:
      "Black Double Fresh nudi intenzivnu kombinaciju dvostruke svežine za osvežavajuće iskustvo.",
    cover_img: require("./assets/images/products/cuba-black-double-fresh-front.webp"),
    keywords:
      "dvostruko, sveže, osvežavajuće, nikotin, snussrbija, online prodaja",
  },
  {
    id: 110,
    title: "White Cherry",
    category: "CUBA",
    meta_description:
      "White Cherry donosi sladak i sočan ukus trešnje sa blagim osvežavajućim tonovima.",
    cover_img: require("./assets/images/products/cuba-white-cherry-front.webp"),
    keywords:
      "trešnja, slatko, osvežavajuće, nikotin, snussrbija, online prodaja",
  },
  {
    id: 111,
    title: "White Cola",
    category: "CUBA",
    meta_description:
      "White Cola pruža autentičan ukus kole sa blagim osvežavajućim efektom.",
    cover_img: require("./assets/images/products/cuba-white-cola-front.webp"),
    keywords:
      "kola, osvežavajuće, nikotin, autentično, snussrbija, online prodaja",
  },
  {
    id: 112,
    title: "White Watermelon",
    category: "CUBA",
    meta_description:
      "White Watermelon nudi osvežavajući i sladak ukus sočne lubenice.",
    cover_img: require("./assets/images/products/cuba-white-watermelon-front.webp"),
    keywords:
      "lubenica, slatko, osvežavajuće, nikotin, snussrbija, online prodaja",
  },
  {
    id: 113,
    title: "White Pineapple",
    category: "CUBA",
    meta_description:
      "White Pineapple donosi tropski i sladak ukus ananasa za egzotično iskustvo.",
    cover_img: require("./assets/images/products/cuba-white-pineapple-front.webp"),
    keywords: "ananas, tropsko, slatko, nikotin, snussrbija, online prodaja",
  },
  {
    id: 114,
    title: "White Ice Spearmint",
    category: "CUBA",
    meta_description:
      "White Ice Spearmint kombinuje osvežavajući efekat spearmint mente sa blagim tonovima.",
    cover_img: require("./assets/images/products/cuba-white-ice-spearmint-front.webp"),
    keywords:
      "spearmint, osvežavajuće, nikotin, blago, snussrbija, online prodaja",
  },
  {
    id: 115,
    title: "White Forest Berries",
    category: "CUBA",
    meta_description:
      "White Forest Berries nudi bogat i osvežavajući ukus šumskog voća.",
    cover_img: require("./assets/images/products/cuba-white-forest-berries-front.webp"),
    keywords:
      "šumsko voće, voćno, osvežavajuće, nikotin, snussrbija, online prodaja",
  },
  {
    id: 116,
    title: "White Double Fresh",
    category: "CUBA",
    meta_description:
      "White Double Fresh nudi intenzivnu svežinu dvostruke arome za osvežavajuće iskustvo.",
    cover_img: require("./assets/images/products/cuba-white-double-fresh-front.webp"),
    keywords:
      "dvostruko, sveže, osvežavajuće, nikotin, snussrbija, online prodaja",
  },
  {
    id: 117,
    title: "Spearmint Slim Strong",
    category: "ZYN",
    meta_description:
      "Spearmint Slim Strong donosi intenzivnu aromu spearminta u praktičnom slim formatu.",
    cover_img: require("./assets/images/products/zyn-spearmint-slim-strong-front.webp"),
    keywords:
      "spearmint, osvežavajuće, nikotin, slim, snussrbija, online prodaja",
  },
  {
    id: 118,
    title: "Fresh Mint Slim",
    category: "ZYN",
    meta_description:
      "Fresh Mint Slim pruža blagu i osvežavajuću aromu sveže mente u slim formatu.",
    cover_img: require("./assets/images/products/zyn-fresh-mint-slim-front.webp"),
    keywords: "menta, osvežavajuće, nikotin, slim, snussrbija, online prodaja",
  },
  {
    id: 119,
    title: "White Blueberry",
    category: "CUBA",
    meta_description:
      "White Blueberry donosi bogat i osvežavajući ukus sočne borovnice, savršen za ljubitelje voćnih i aromatičnih iskustava.",
    cover_img: require("./assets/images/products/cuba-white-blueberry-front.webp"),
    keywords:
      "borovnica, voćno, osvežavajuće, nikotin, snussrbija, online prodaja",
  },
];

export const home_slider = [
  {
    id: 1,
    bg: "#b2dfda",
    img: require("./assets/images/pocetna_12.webp"),
    title: "Ponuda",
    desc: "U našoj ponudi možete pronaći veliki izbor Snusa najpoznatijih brendova na tržištu. Odaberite između različitih ukusa, intenziteta nikotina i vrsta pakovanja.",
    textColor: "#025E4F",
    titleEN: "Offer",
    descEN:
      "In our offer, you can find a wide selection of Snus from the most well-known brands on the market. Choose from different flavors, nicotine strengths, and packaging types.",
  },
  {
    id: 2,
    bg: "#bfc2c8",
    img: require("./assets/images/home1.webp"),
    title: "Izbor",
    desc: "Kod nas možete pronaći najbolji izbor Snusa po najpovoljnijim cenama na tržištu. Odaberite između najpoznatijih svetskih brendova i uživajte u savršenom spoju ukusa i kvaliteta.",
    textColor: "#cb0d29",
    titleEN: "Choice",
    descEN:
      "With us, you can find the best selection of Snus at the most affordable prices on the market. Choose from the most famous international brands and enjoy the perfect combination of taste and quality.",
  },
  {
    id: 3,
    //bg: `linear-gradient(to bottom, #cb0d29 0%, #cb0d29 60.7%, hsl(0deg,0%,96%) 60.7%)`,//"#cb0d29, //60%
    bg: "#f5841f",
    img: require("./assets/images/pocetna_11.webp"),
    title: "Preporuka",
    desc: "Naš tim stručnjaka za snus će Vam pomoći u odabiru najboljeg brenda i ukusa za Vas i Vaše želje.",
    textColor: "black",
    titleEN: "Suggestion",
    descEN:
      "Our team of snus experts will help you choose the best one for your taste and preferences.",
  },
];

export const discountOptions = [
  {
    id: 1,
    quantity: 4,
    maxQuantity: 49,
    backgroundColor: "#008080",
    message: "Besplatna dostava",
    messageEN: "Free Delivery",
  },
  // {
  //   id: 2,
  //   quantity: 4,
  //   backgroundColor: "#00AEAE",
  //   message: "Extra -6.5%",
  //   messageEN: "Extra -6.5%",
  // },
  {
    id: 3,
    quantity: 9,
    backgroundColor: "#008080",
    message: "Extra -10%",
    messageEN: "Extra -10%",
  },
  // {
  //   id: 4,
  //   quantity: 29,
  //   backgroundColor: "#00AEAE",
  //   message: "do -30% ",
  //   messageEN: "Up to -30%",
  // },
  {
    id: 5,
    quantity: 49,
    backgroundColor: "#008080",
    message: "Veliki -22%",
    messageEN: "Large -22%",
  },
];

const data = [
  {
    id: 1,
    image:
      "https://a.storyblok.com/f/153226/1920x1080/b9fe274750/three-penguin-chairs.webp",
    title: "Televizori",
    text: "Otkrijte najnovije modele televizora",
    link: "/shop/1",
    background: "#121212",
  },
  {
    id: 2,
    image:
      "https://a.storyblok.com/f/153226/1920x1080/452da1483f/menu_elizabeth_lounge_chair_elizabeth_ottoman-extended-1920x1080.webp",
    title: "Telefoni",
    text: "Odaberite savršen telefon za sebe",
    link: "/shop/3",
    background: "#140005",
  },
  {
    id: 3,
    image:
      "https://a.storyblok.com/f/153226/1920x1080/0b0f06a2cf/pagode-1920x1080.webp",
    title: "Kamere",
    text: "Napravite najbolje fotografije uz našu selekciju kamera",
    link: "/shop/4",
    background: "#464033",
  },
  {
    id: 4,
    image:
      "https://a.storyblok.com/f/153226/1920x1080/0b0f06a2cf/pagode-1920x1080.webp",
    title: "Laptopovi",
    text: "Radite i zabavljajte se uz najnovije modele laptopova.",
    link: "/shop/2",
    background: "#7E7C73",
  },
];
export default data;
